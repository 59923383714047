<template>
  <div class="warp">
    <div class="wktime">
      <div v-for="item in OrganList" :key="item.OId" class="listRid" @click="gotoUrl(item.OId)">
        <div class="listRidtitle">{{ item.OrganName }}</div>
        <div class="listRidname">
          <span>联系人: {{ item.LeaderCadre }}</span>
          <span>联系电话: <a :href="'tel:' + item.LeaderTel" style="color:#000;text-decoration: underline;">{{ item.LeaderTel }}</a></span>
        </div>
        <div class="listRidname">地址: {{ item.Address }}</div>
        <div class="listRidname" style="font-size:13px">
          工作时间:<br />
            {{ item.WorkTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { WeGetAppletOrganList } from "@/api/index";

export default {
  data() {
    return {
      OrganList: [],
    };
  },
  created() {
    this.getAppletOrganList();
  },
  methods: {
    //跳转小程序页面
    gotoUrl(OId) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../home/concat/index?OId=" + OId,
        });
      }
    },
    // 组织机构列表
    getAppletOrganList: function () {
      WeGetAppletOrganList().then((res) => {
        this.OrganList = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  background: rgb(245, 242, 242);
  border-radius: 15px;
  box-shadow: 1px 1px 1px #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>